import React from 'react';
import { CTAButton } from '../shared/CTAButton';

export const MapSection = () => {
  const advantages = [
    {
      msg: 'Schonend für die Umwelt, nachhaltig und klimafreundlich.',
    },
    {
      msg: 'Effektiv und einfach Energiekosten sparen.',
    },
    {
      msg: 'Zugeschnitten auf Ihre Wünsche.',
    },
  ];
  return (
    <>
      <div id="locations" className="md:py-[100px] py-[40px] px-[15px]">
        <div className="max-w-full 2xl:max-w-[1520px] mx-auto ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 items-center">
            <div className="">
              <h1 className=" text-[40px] md:text-[69px] 2xl:text-[72px] leading-normal md:leading-[72px] font-medium ">
                Siegen, Köln, Essen,
                Dortmund und noch mehr...
              </h1>
              <p className="py-8 md:text-[20px] leading-normal md:leading-[30px] font-normal ">
                Bereits über +20 Städte in ganz Nordrhein Westfalen haben dank uns einen
                Zuwachs von Solarenergie. Werden Sie noch heute ein Teil davon!
              </p>
              <div className="flex flex-col gap-3 pb-8">
                {advantages.map((data, index) => (
                  <div className="flex gap-2 items-center" key={index}>
                    <img src="/assets/tick-blue.svg" className="w-[26px] h-[26px]" alt="" />
                    <p className=" text-[20px] font-medium text-[#071943] ">{data.msg}</p>
                  </div>
                ))}
              </div>
              <CTAButton />
            </div>
            <div>
              <img src="/assets/Frame 57.svg" className="max-w-full h-auto" alt="" />
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/Line 1.svg" className="max-w-full w-full h-auto  " alt="" />
    </>
  );
};
