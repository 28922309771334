import React from 'react';
import { HeroSection } from './HeroSection';
import { CardBox } from './CardBox';
import { StorageSection } from './StorageSection';
import { CTASection } from '../shared/CTASection';
import { ModelSection } from './ModelSection';
import { TeamSection } from './TeamSection';
import { MapSection } from './MapSection';
import { ReviewsSection } from './ReviewsSection';
import { FaqSection } from '../shared/FaqSection';

export const Home = () => {
  return (
    <div>
      <HeroSection />
      <div className=" mt-5 md:mt-[-50px] relative z-[2]">
        <CardBox />
      </div>
      <StorageSection />
      <ModelSection />
      <TeamSection />
      <MapSection />
      {/* <ProjectsSection /> */}
      {<ReviewsSection />}
      <CTASection />
      <FaqSection />
    </div>
  );
};
