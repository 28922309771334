import React, { useState, useRef } from 'react';

export const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeFilter, setActiveFilter] = useState('Verpachtung');
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      category: "Verpachtung",
      faqItems: [
        {
          id: 1,
          title: 'Welche Vorteile bietet die Solarverpachtung mit Entdecke Solar?',
          content: 'Die Solarverpachtung mit Entdecke Solar bietet Ihnen mehrere Vorteile: Zusätzliche Einnahmen ohne eigene Investitionskosten, regelmäßige Pachteinnahmen, aktiver Beitrag zur Energiewende, Wertsteigerung Ihrer Immobilie, effiziente Nutzung ungenutzter Flächen und keine laufenden Kosten oder Wartungsaufwand für Sie.'
        },
        {
          id: 2,
          title: 'Welche Rolle spielt Entdecke Solar bei der Solarverpachtung?',
          content: 'Entdecke Solar fungiert als erfahrener Vermittler und Projektmanager. Wir unterstützen Sie bei jedem Schritt der Solarverpachtung - von der ersten Beratung über die Planung und Installation bis hin zur laufenden Betreuung. Wir koordinieren alle beteiligten Parteien und stellen sicher, dass Ihr Projekt reibungslos und profitabel umgesetzt wird.'
        },
        {
          id: 3,
          title: 'Wie funktioniert die Solarverpachtung mit Entdecke Solar?',
          content: 'Die Solarverpachtung basiert auf einem langfristigen Vertrag, der es ermöglicht, Ihre Fläche für die Solarenergieerzeugung zu nutzen. Entdecke Solar vermittelt Investoren, die die Solaranlage auf Ihrer Fläche errichten und betreiben. Sie als Verpächter erhalten dafür regelmäßige Pachteinnahmen, ohne sich um Investition, Betrieb oder Wartung kümmern zu müssen.'
        },
        {
          id: 4,
          title: 'Wie lange läuft ein typischer Pachtvertrag?',
          content: 'Typische Pachtverträge für Solaranlagen haben eine Laufzeit von 20 bis 30 Jahren. Diese lange Vertragsdauer gewährleistet Ihnen stabile, langfristige Einnahmen und gibt den Investoren Planungssicherheit. Nach Ablauf des Vertrages kann dieser verlängert oder die Anlage zurückgebaut werden.'
        },
        {
          id: 5,
          title: 'Welche Pflichten habe ich als Verpächter?',
          content: 'Als Verpächter haben Sie kaum Pflichten. Sie stellen lediglich Ihre Fläche zur Verfügung und gewähren Zugang für Installation und Wartung. Alle Kosten und Verantwortlichkeiten für Betrieb, Wartung und Versicherung der Anlage übernimmt der Investor. Ihre Hauptaufgabe ist es, die regelmäßigen Pachteinnahmen zu genießen.'
        },
        {
          id: 6,
          title: 'Wie hoch sind die Pachteinnahmen?',
          content: 'Die Höhe der Pachteinnahmen variiert je nach Größe und Beschaffenheit Ihrer Fläche sowie der installierten Leistung der Solaranlage. Wir erstellen Ihnen gerne ein individuelles Angebot.'
        }
      ]
    },
    {
      category: "Projekte",
      faqItems: [
        {
          id: 1,
          title: 'Welche Genehmigungen sind für ein Solarprojekt erforderlich?',
          content: 'Die erforderlichen Genehmigungen hängen von der Art und Größe des Projekts ab. Üblicherweise benötigen Sie eine Baugenehmigung, eine Netzanschlusszusage vom lokalen Netzbetreiber und ggf. umweltrechtliche Genehmigungen. Entdecke Solar unterstützt Sie bei allen notwendigen Genehmigungsverfahren.'
        },
        {
          id: 2,
          title: 'Wie wird die Wirtschaftlichkeit eines Solarprojekts berechnet?',
          content: 'Die Wirtschaftlichkeit wird anhand verschiedener Faktoren berechnet, darunter die Investitionskosten, erwartete Stromerträge, Einspeisevergütungen oder Stromverkaufspreise, sowie Betriebs- und Wartungskosten. Entdecke Solar erstellt für Sie eine detaillierte Wirtschaftlichkeitsberechnung, um die Rentabilität Ihres Projekts zu demonstrieren.'
        }
      ]
    },
    {
      category: "Technologie",
      faqItems: [
        {
          id: 1,
          title: 'Wie funktioniert Solarenergie?',
          content: 'Solarenergie wird durch Photovoltaik-Zellen erzeugt, die Sonnenlicht direkt in elektrischen Strom umwandeln. Diese Zellen bestehen aus Halbleitermaterialien, meist Silizium. Wenn Sonnenlicht auf die Zellen trifft, werden Elektronen freigesetzt, die einen elektrischen Strom erzeugen. Dieser Gleichstrom wird dann durch einen Wechselrichter in Wechselstrom umgewandelt, der in Haushalten und im Stromnetz genutzt werden kann.'
        },
        {
          id: 2,
          title: 'Welche Arten von Solarzellen gibt es?',
          content: 'Es gibt verschiedene Arten von Solarzellen: Monokristalline Zellen sind sehr effizient und werden aus einem einzigen Siliziumkristall hergestellt. Polykristalline Zellen sind etwas weniger effizient, aber kostengünstiger in der Herstellung. Dünnschichtzellen sind flexibler und leichter, haben aber eine geringere Effizienz. Neuere Technologien wie Perowskit-Zellen oder organische Solarzellen befinden sich in der Entwicklung und versprechen weitere Verbesserungen in Effizienz und Kostenreduktion.'
        },
        {
          id: 3,
          title: 'Wie effizient sind Solarzellen?',
          content: 'Die Effizienz von Solarzellen, also der Anteil des Sonnenlichts, der in elektrische Energie umgewandelt wird, variiert je nach Technologie. Handelsübliche Silizium-Solarzellen erreichen Effizienzen von etwa 15-22%. Im Labor wurden bereits Effizienzen von über 40% mit Mehrfachschicht-Solarzellen erreicht. Die Effizienz verbessert sich ständig durch technologische Fortschritte, was Solarenergie immer wettbewerbsfähiger macht.'
        }
      ]
    },
    {
      category: "Umgebung",
      faqItems: [
        {
          id: 1,
          title: 'Welche Auswirkungen haben Solaranlagen auf die Umwelt?',
          content: 'Solaranlagen haben überwiegend positive Auswirkungen auf die Umwelt. Sie produzieren sauberen, erneuerbaren Strom und reduzieren CO2-Emissionen. Moderne Solarmodule sind recycelbar, und bei Freiflächenanlagen können oft Maßnahmen zur Förderung der Biodiversität umgesetzt werden.'
        },
        {
          id: 2,
          title: 'Können Solaranlagen in Naturschutzgebieten errichtet werden?',
          content: 'Die Errichtung von Solaranlagen in Naturschutzgebieten ist in der Regel stark eingeschränkt oder nicht erlaubt. Entdecke Solar prüft sorgfältig alle umweltrechtlichen Aspekte und arbeitet eng mit Behörden zusammen, um geeignete Standorte zu finden, die sowohl ökologisch als auch ökonomisch sinnvoll sind.'
        },
        {
          id: 3,
          title: 'Wie werden Solaranlagen am Ende ihrer Lebensdauer entsorgt?',
          content: 'Am Ende ihrer Lebensdauer werden Solaranlagen fachgerecht demontiert und recycelt. Die meisten Komponenten, insbesondere Glas, Aluminium und Halbleitermaterialien, können wiederverwertet werden. Entdecke Solar arbeitet mit zertifizierten Entsorgungsunternehmen zusammen, um eine umweltfreundliche und gesetzeskonforme Entsorgung sicherzustellen.'
        }
      ]
    }
  ];
  /*{
    category: "Projekte",
    faqItems: [
    ]
  },
  {
    category: "Technologie",
    faqItems: [
    ]
  },
  {
    category: "Umgebung",
    faqItems: [
    ]
  }*/

  const handleFilterClick = (filterName) => {
    setActiveFilter(filterName);
    setActiveIndex(null);
  };

  const activeFaqItems = faqData.find(item => item.category === activeFilter)?.faqItems || [];

  return (
    <div className="grid grid-cols-12 gap-5">
      <div className="md:col-span-3 col-span-12 md:border-r md:border-[#0719431A]">
        <div className="md:max-w-[200px] max-w-full w-full">
          <div className="flex flex-col gap-3">
            <p className="text-[#07194380] font-medium text-[20px]">Nach Kategorien filtern</p>
            {faqData.map((item) => (
              <button
                key={item.category}
                onClick={() => handleFilterClick(item.category)}
                className={`px-[28px] py-[14px] rounded-[70px] border hover:bg-[#071943] hover:border-transparent items-center hover:text-white transition-all text-[20px] font-semibold flex gap-2 ${activeFilter === item.category
                  ? 'bg-[#071943] border-transparent text-white'
                  : 'border-[#0719434D] text-[#071943]'
                  }`}
              >
                {activeFilter === item.category && <img src="/assets/Vector.svg" className="w-[17px] h-[25px]" alt="" />}
                {item.category}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="md:col-span-9 col-span-12">
        <div className="md:pl-3 flex flex-col gap-3">
          {activeFaqItems.length > 0 ? (
            activeFaqItems.map((item, index) => (
              <div key={item.id}>
                <div
                  className="md:p-[20px] p-[10px] rounded-[10px] cursor-pointer border border-[#07194326] bg-white"
                  style={{ boxShadow: '0px 0px 20px 0px #0000000D' }}
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={activeIndex === index ? '/assets/minus-sign.png' : '/assets/plus.svg'}
                      className="w-[14px] h-[14px]"
                      alt="toggle icon"
                    />
                    <h3 className="font-medium text-[20px] leading-[28px]">{item.title}</h3>
                  </div>
                  <div
                    ref={(el) => (contentRefs.current[index] = el)}
                    className={`overflow-hidden transition-all duration-500 ease-in-out ${activeIndex === index ? 'max-h-[300px]' : 'max-h-0'
                      }`}
                    style={{
                      height: activeIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0px',
                    }}
                  >
                    <div className="p-[10px] mt-2">
                      <p className="text-[16px] leading-[24px]">{item.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No FAQs available for this category.</p>
          )}
        </div>
      </div>
    </div>
  );
};