import React from 'react';

export const Imprint = () => {
    return (
        <div className="max-w-2xl mx-auto p-6 font-sans">
            <h1 className="text-3xl font-bold mb-6 text-gray-800 border-b-2 border-gray-300 pb-2">Impressum</h1>

            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Entdecke Solar GbR</h2>

            <p className="mb-3">
                <span className="font-semibold">Geschäftsführung:</span> Agon Zeneli
            </p>

            <p className="mb-3">
                <span className="font-semibold">Anschrift:</span> Bischof-Ferdinand-Weg 8, 57368 Lennestadt
            </p>

            <p className="mb-3">
                <span className="font-semibold">Verantwortlich für den Inhalt:</span> Agon Zeneli
            </p>
        </div>
    );
};