import React, { useState } from 'react';
import { CTAButton } from '../shared/CTAButton';
export const ModelSection = () => {
  const [activeButton, setActiveButton] = useState('Photovoltaikanlagen');

  const ctx = [
    {
      name: 'Photovoltaikanlagen',
      active: activeButton === 'Photovoltaikanlagen',
      subtext: 'Der Weg zur unabhängigen und umweltfreundlichen Energielösung beginnt  hier.Mit einer Photovoltaikanlage nutzen Sie eine unerschöpfliche  Quelle an Energie, die nicht nur Ihre Kosten senkt, sondern auch den  CO₂-Ausstoß unseres Planeten.',
      advantages: [
        'Nachhaltige, klimafreundliche Stromgewinnung.',
        'Unkompliziert und portabel.',
        'Effektiv und einfach Energiekosten sparen.'
      ],
      image: '/assets/image-2.svg'
    },
    {
      name: 'Balkonkraftwerk',
      active: activeButton === 'Balkonkraftwerk',
      subtext: 'Die kleinste und günstigste Möglichkeit, Solarenergie zu nutzen, ist der Einsatz von Balkon-Solarmodulen. Diese lassen sich ganz einfach an der Wand oder dem Geländer Ihres Balkons befestigen und liefern ausreichend Strom für kleine Haushaltsgeräte oder Elektronikgeräte.',
      advantages: [
        'Schonend für die Umwelt, nachhaltig und klimafreundlich.',
        'Unkompliziert er baubar auf bereits vorhandenen Flächen.',
        'Unabhängig vom globalen Strompreis.',
        'Deutschlandweit verfügbar.'
      ],
      image: '/assets/image-1.svg'
    },
    {
      name: 'Solargenerator',
      active: activeButton === 'Solargenerator',
      subtext: `Dank der heutigen Technik ist es möglich per Solaranlagen selbst
bei der Wassererwärmung zu sparen.Ein 4-Personen-Haushalt zahlt jährlich 660 Euro im Durchschnitt für warmes Wasser, das mit Erdgas erwärmt wird.Fangen Sie also noch heute an zu sparen!`,
      advantages: [
        'Klima- und Umweltfreundlich.',
        'Unabhängig von erschöpfbaren Ressourcen.'
      ],
      image: '/assets/image-3.svg'
    }
  ];

  const handleClick = (name) => {
    setActiveButton(name);
  };

  const business = [
    { number: '100', value: '%', tile: 'Engagement für Nachhaltigkeit' },
    { number: '50', value: '+', tile: 'geplante Projekte' },
    { number: '10', value: '+', tile: 'Vertriebler' },
  ];

  const cards = [
    {
      id: 1,
      img: '/assets/leaves.svg',
      title: 'Maximieren Sie ihre Rendite',
      desc: 'Investieren Sie in Solarenergie und steigern Sie Ihre finanziellen Rückflüsse. Profitieren Sie von staatlichen Förderungen und reduzieren Sie langfristig Ihre Energiekosten.',
    },
    {
      id: 2,
      img: '/assets/sun.svg',
      title: 'Sichern Sie Ihre Zukunft nachhaltig',
      desc: 'Reduzieren Sie aktiv Ihren CO2-Fußabdruck und tragen Sie zum Klimaschutz bei. Machen Sie sich unabhängig von steigenden Energiepreisen und sichern Sie Ihre Zukunft.',
    },
    {
      id: 3,
      img: '/assets/wold.svg',
      title: 'Wir vergleichen - Sie entscheiden',
      desc: 'Nutzen Sie unsere umfassende Marktkenntnis, um die besten Angebote für Ihre individuellen Bedürfnisse zu finden. Unser Expertenteam berät Sie kompetent und unverbindlich',
    },
    {
      id: 4,
      img: '/assets/Frame 86.svg',
      title: 'Profitieren Sie ohne Risiko',
      desc: 'Genießen Sie die Vorteile der Solarenergie mit transparenten Verträgen und umfassenden Garantien. Unsere erfahrenen Techniker sorgen für eine professionelle Installation und einen reibungslosen Betrieb Ihrer Anlage.',
    },
  ];

  return (
    <div id="services" className="py-[100px] px-[15px]">
      <div className="2xl:max-w-[1520px] max-w-full w-full mx-auto">
        <div className="flex justify-center flex-wrap gap-3 pb-[80px]">
          {ctx.map((btn) => (
            <button
              key={btn.name}
              onClick={() => handleClick(btn.name)}
              className={`px-[28px] py-[14px] rounded-[70px] border hover:bg-[#071943] hover:border-transparent items-center hover:text-white transition-all text-[20px] font-semibold flex gap-2 ${btn.active ? 'bg-[#071943] border-transparent text-white' : 'border-[#0719434D] text-[#071943]'
                }`}
            >
              {btn.active && <img src="/assets/Vector.svg" className="w-[17px] h-[25px]" alt="" />}
              {btn.name}
            </button>
          ))}
        </div>
        <div>
          {ctx.map((item) => (
            item.active && (
              <div key={item.name}>
                <img src="/assets/Line 1.svg" className="max-w-full w-full h-auto" alt="" />
                <div className="py-[20px] md:py-[80px]">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 2xl:gap-[100px] items-center">
                    <div>
                      <h1 className="text-[40px] md:text-[72px] font-medium leading-normal md:leading-[72px] text-[#071943]">
                        {item.name}
                      </h1>
                      <p className="py-3 text-[20px] text-[#071943] font-normal leading-[30px]">
                        {item.subtext}
                      </p>
                      <div className="flex flex-col gap-3">
                        {item.advantages.map((advantage, index) => (
                          <div className="flex gap-2 items-center" key={index}>
                            <img src="/assets/tick-blue.svg" className="w-[26px] h-[26px]" alt="" />
                            <p className="text-[20px] font-medium text-[#071943]">{advantage}</p>
                          </div>
                        ))}

                      </div>

                      <CTAButton />

                    </div>
                    <div>
                      <img src={item.image} className="max-w-full h-auto w-full" alt="" />
                    </div>
                  </div>
                </div>
                <img src="/assets/Line 1.svg" className="max-w-full w-full h-auto" alt="" />
              </div>
            )
          ))}
        </div>
        <div className="py-[20px] md:py-[80px]">
          <div className="max-w-[1120px] w-full mx-auto">
            <div className="flex justify-center">
              <button className="px-[28px] py-[14px] rounded-[70px] bg-[#071943] text-white items-center transition-all text-[20px] font-semibold"
                onClick={() => window.open('https://7vjgx7um9hq.typeform.com/to/bLR2sHY8', '_blank', 'noopener,noreferrer')}>
                Wir vergleichen für Sie!
              </button>
            </div>
            <div className="pt-[60px] flex flex-col md:flex-row justify-between">
              {business.map((item, index) => (
                <div className="max-w-[340px] w-full text-center" key={index}>
                  <h1 className={`font-bold text-[40px] ${item.value === 'MW+' ? 'md:text-[87px]' : 'md:text-[90px]'} leading-normal md:leading-[90px] text-[#071943]`}>
                    {item.number} <span className="text-[#FED63C]">{item.value}</span>
                  </h1>
                  <p className="pt-2 text-[18px] uppercase md:text-[22px] font-medium text-[#071943BF]">{item.tile}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img src="/assets/Line 1.svg" className="max-w-full w-full h-auto pt-[30px]" alt="" />
        <div className="py-[60px] px-[15px]">
          <div className="2xl:max-w-[1520px] 2xl:px-[90px] grid grid-cols-1 lg:grid-cols-2 gap-[30px] 2xl:gap-[80px] mx-auto">
            {cards.map((item, index) => (
              <div
                key={index}
                className="rounded-[20px] py-[30px] lg:py-[56px] px-[30px] lg:px-[60px] bg-white border border-[#0000000D]"
                style={{ boxShadow: '0px 4px 56px 0px #0000001A' }}
              >
                <img src={item.img} className="md:w-[95px] w-[50px] h-[50px] md:h-[95px] pb-3" alt="" />
                <div className="pt-5">
                  <h1 className="text-[32px] font-medium text-[#071943] leading-[32px]">{item.title}</h1>
                  <p className="text-[18px] m-0 pt-2 md:text-[20px] text-[#071943BF] font-normal leading-[30px]">
                    {item.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};