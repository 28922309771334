import { CTASection } from "../shared/CTASection";
import { FaqSection } from "../shared/FaqSection";
import { BenefitsSection } from "./BenefitsSection";
import { HeroSection } from "./HeroSection";
import { InformationSection } from "./InformationSection";


export const Leasing = () => {
    return (
        <div>
            <HeroSection />
            <InformationSection />
            <BenefitsSection />
            <CTASection />
            <FaqSection />

        </div>
    );
};
