import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Home } from './components/home/Home';
import { Imprint } from './components/imprint/Imprint';
import { Footer } from './components/layout/Footer';
import { Privacy } from './components/privacy/Privacy';
import { Leasing } from './components/leasing/Leasing';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/verpachtung" element={<Leasing />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
