const BenefitItem = ({ icon, title, subtext }) => {
    return (
        <div className="flex flex-col items-center text-center">
            <div className="w-[100px] h-[100px] bg-[#071943] rounded-lg flex items-center justify-center mb-4">
                <img src={icon} alt={title} className="w-16 h-16" /> {/* Icon size set to 64x64 */}
            </div>
            <h3 className="text-xl font-bold mb-2">{title}</h3>
            <p className="text-gray-600">{subtext}</p>
        </div>
    );
};

export default BenefitItem;