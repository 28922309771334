import React, { useState } from 'react';
import { CTAButton } from "../shared/CTAButton";

export const InformationSection = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div id="leasing-info" className="md:py-[100px] py-[40px] px-[15px] flex flex-col gap-36">
            <div className="max-w-full 2xl:max-w-[1520px] mx-auto ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
                    <div className="order-2 lg:order-1">
                        <h1 className="text-[40px] md:text-[72px] font-medium leading-normal md:leading-[72px] text-[#071943]">
                            Was ist eine Verpachtung/Pacht?
                        </h1>
                        <div className="flex flex-col gap-3 py-8 md:text-[20px] leading-normal md:leading-[30px] font-normal ">
                            <span className="md:text-[24px] font-bold">Ein Überblick</span>
                            <span>Bei einer Pacht sind zwei Parteien involivert: <span className="font-bold">Der Verpächter und der Pächter</span></span>
                            <span><span className="font-bold">Der Verpächter</span> verfügt über eine freie Dach- bzw. Freifläche,
                                welche für die Anbringung von Solarpanels geeignet ist.</span>
                            <span><span className="font-bold">Der Pächter</span> investiert in diese Dach- bzw. Freifläche in Form von monatlichen Zahlungen
                                an den Verpächter.</span>

                            <div className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                <span className="block mt-4">Die Pacht bietet beiden Parteien zahlreiche Vorteile:</span>
                                <ul className="list-disc list-inside pl-4 mt-2">
                                    <li>Der Verpächter erhält regelmäßige Einnahmen, ohne selbst investieren zu müssen.</li>
                                    <li>Der Pächter kann die Fläche für die Solarenergieerzeugung nutzen, ohne sie kaufen zu müssen.</li>
                                    <li>Es entsteht eine Win-Win-Situation für beide Seiten.</li>
                                    <li>Die Nutzung erneuerbarer Energien wird gefördert, was zum Klimaschutz beiträgt.</li>
                                    <li>Ungenutzte Flächen werden sinnvoll und gewinnbringend eingesetzt.</li>
                                </ul>
                                <p className="mt-4">
                                    Durch die Verpachtung für Solaranlagen können Grundstücks- und Immobilienbesitzer von der Energiewende profitieren,
                                    ohne selbst das Risiko und die Kosten einer Investition tragen zu müssen. Gleichzeitig ermöglicht es Investoren,
                                    Solaranlagen an optimalen Standorten zu errichten und zu betreiben.
                                </p>
                            </div>
                        </div>
                        <span
                            className="text-[#071943] font-semibold text-[18px] cursor-pointer hover:underline"
                            onClick={toggleExpand}
                        >
                            {isExpanded ? "Weniger anzeigen" : "Mehr lesen"}
                        </span>
                    </div>
                    <div className="order-1 lg:order-2">
                        <img src="/assets/Handshake.svg" className="max-w-full h-auto" alt="" />
                    </div>
                </div>
            </div>

            <div className="max-w-full 2xl:max-w-[1520px] mx-auto ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
                    <div className="order-1">
                        <img src="/assets/OpenSpace.svg" className="max-w-full h-auto" alt="" />
                    </div>
                    <div className="order-2">
                        <h1 className="text-[40px] md:text-[72px] font-medium leading-normal md:leading-[72px] text-[#071943]">
                            Du hast eine Freifläche?
                        </h1>
                        <p className="flex flex-col gap-3 py-8 md:text-[20px] leading-normal md:leading-[30px] font-normal ">
                            Nutzen dein ungenutztes Land gewinnbringend! Ob Ackerfläche, Brachland oder Ähnliches - wir helfen dir, daraus eine nachhaltige Einnahmequelle zu machen.
                        </p>
                        <CTAButton text="Jetzt verpachten" />
                    </div>
                </div>
            </div>

            <div className="max-w-full 2xl:max-w-[1520px] mx-auto ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 items-center">
                    <div className="order-2 lg:order-1">
                        <h1 className="text-[40px] md:text-[72px] font-medium leading-normal md:leading-[72px] text-[#071943]">
                            Du hast eine freie Dachfläche?
                        </h1>
                        <p className="flex flex-col gap-3 py-8 md:text-[20px] leading-normal md:leading-[30px] font-normal ">
                            Besitzen Sie eine ungenutzte Dachfläche? Nutzen Sie diese Chance, um nicht nur Ihre Immobilie aufzuwerten, sondern auch von attraktiven Pachtzahlungen zu profitieren! Durch die Vermietung Ihrer Dachfläche für Photovoltaikanlagen können Sie langfristige Einnahmen erzielen
                        </p>
                        <CTAButton text="Jetzt verpachten" />
                    </div>
                    <div className="order-1 lg:order-2">
                        <img src="/assets/RoofSpace.svg" className="max-w-full h-auto" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}