import React from 'react';
import { CTAButton } from '../shared/CTAButton';
import TrustPilotWidget from './TrustPilotWidget';

const items = [
  {
    msg: 'Kostenlose Erstberatung vor Ort',
  },
  {
    msg: 'Unabhängig von globalen Marktpreisen.',
  },
  {
    msg: 'Zugeschnitten auf Ihre Wünsche.',
  },
  {
    msg: 'Persönlicher Ansprechpartner für Fragen',
  },
];

export const HeroSection = () => {
  return (
    <div className="w-full lg:min-h-[791px] bgImg bg-cover relative">
      <div className="lg:pt-[100px] 2xl:pt-[150px] pb-[40px] pt-[40px] lg:container px-[15px] xl:max-w-[1286px] mx-auto relative z-10">
        <div className="flex md:flex-row flex-col items-center gap-5 2xl:gap-[150px]">
          <div className="md:max-w-[769px] w-full max-w-full">
            <h1 className="md:text-[72px] text-[40px] font-medium md:leading-[79.2px] leading-normal text-white">
              Sparen Sie jetzt bis zu {' '}
              <span className="text-[#FED63C] font-semibold mx-2">78%</span> mit nachhaltiger Solar Energie
            </h1>

            <div className="py-8 flex gap-4 items-center">
              <CTAButton />
              <a href="#services" className="md:py-[14px] py-[8px] md:px-[28px] px-[15px] rounded-[10px] bg-transparent border border-[#FFFFFF4D] text-[#fff] font-semibold text-[20px]">
                Unsere Dienstleistungen
              </a>
            </div>

            <div className="flex gap-2">
              <img src="/assets/users.svg" className="w-[56px] h-auto" alt="" />
              <p className="font-normal text-[24px] leading-[28.8px] text-white" style={{ boxShadow: '0px 0px 20px 0px #0719434D' }}>
                Über <span className="text-[#FED63C]">50+</span> zufriedene Benutzer <br />
                von Privathaushalten bis zu Unternehmen
              </p>
            </div>



          </div>

          <div className="max-w-[386px] w-full">
            <div className="pb-5">
              <p className="text-[22px] font-medium text-[#FFFFFFD9]">Ein Neuer Blick auf Energie - Unsere Vorteile</p>
            </div>
            <div className="flex flex-col gap-3">
              {items.map((data, index) => (
                <div className="flex gap-2 items-center" key={index}>
                  <img src="/assets/tick.svg" className="w-[26px] h-[26px]" alt="" />
                  <p className="text-[22px] font-medium text-[#FFFFFF]">{data.msg}</p>
                </div>
              ))}

              <TrustPilotWidget />

            </div>
          </div>
        </div>
      </div>
      {/* Shadow image */}
      <div className="absolute inset-0 pointer-events-none">
        <img src="/assets/shadow.svg" className="max-w-full absolute bottom-0 left-0 right-0" alt="" style={{ pointerEvents: 'none' }} />
      </div>
    </div>
  );
};