import React from 'react';
import { CTAButton } from './CTAButton';

export const CTASection = () => {
  return (
    <div className="pt-[55px] pb-[75px] px-[15px] bg-[#0719430D]">
      <div className="flex text-center flex-col items-center gap-2">
        <div className="">
          <img src="/assets/Group 9.svg" alt="" className="w-[200px] h-auto" />
        </div>
        <h1 className="text-[40px] md:text-[72px] leading-normal md:leading-[72px] text-[#071943] font-medium">
          Jetzt Vorteile vergleichen
        </h1>

        <p className="text-[18px] md:text-[20px] leading-normal md:leading-[30px] py-4 text-[#071943] font-normal">
          Entdecken Sie die besten Optionen, die perfekt zu Ihren Bedürfnissen passen und Ihnen langfristig Vorteile sichern.
        </p>
        <CTAButton />
      </div>
    </div>
  );
};
