import React, { useEffect, useRef } from 'react';

const TrustPilotWidget = () => {
    const widgetRef = useRef(null);

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(widgetRef.current, true);
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div
            ref={widgetRef}
            className="trustpilot-widget"
            data-locale="de-DE"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-businessunit-id="67141740d9953a6e98934e9c"
            data-style-height="52px"
            data-style-width="100%"
        >
            <a href="https://de.trustpilot.com/review/entdecke.solar" target="_blank" rel="noopener">
                Trustpilot
            </a>
        </div>
    );
};

export default TrustPilotWidget;