import React from 'react';
import { CTAButton } from '../shared/CTAButton';
export const StorageSection = () => {
  const devices = [
    {
      number: 1,
      desc: 'Kontaktaufnahme für ein Angebot',
    },
    {
      number: 2,
      desc: 'Kostenloses Beratungsgespräch',
    },
    {
      number: 3,
      desc: 'Maßgeschneidertes Angebot erhalten',
    },
    {
      number: 4,
      desc: 'Auf die Installation warten',
    },
  ];

  return (
    <div className="md:py-[100px] py-[40px] bg-[#0719430D]">
      <div className="max-w-[986px] w-full mx-auto text-center">
        <h1 className=" font-medium md:text-[56px] text-[30px] text-[#071943] leading-normal md:leading-[56px]">
          Schneller und reibungsloser Ablauf
        </h1>
      </div>
      <div className=" max-w-[1120px] w-full mx-auto">
        <div className="grid py-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
          {devices.map((items, index) => (
            <div className="text-center flex flex-col gap-2 items-center" key={index}>
              <div className="w-[98px] h-[98px] rounded-[50%]  bg-[#071943] text-[#FED63C] font-semibold text-[58.53px] flex items-center justify-center ">
                {items.number}
              </div>
              <p className="text-[20px] text-[#071943] font-semibold max-w-[198px] w-full mx-auto ">{items.desc}</p>
            </div>
          ))}
        </div>
        <div className="pt-4 flex justify-center">
          <CTAButton />
        </div>
      </div>
    </div>
  );
};
