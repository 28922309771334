import React from 'react';

export const Privacy = () => {
    return (
        <div className="max-w-2xl mx-auto p-6 font-sans">
            <h1 className="text-3xl font-bold mb-6 text-gray-800 border-b-2 border-gray-300 pb-2">Datenschutzrichtlinien</h1>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">1. Einleitung</h2>
                <p className="mb-3">Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. Diese Datenschutzrichtlinien informieren Sie darüber, wie wir Ihre Daten erheben, verarbeiten und schützen, wenn Sie unsere Website nutzen.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">2. Verantwortlicher</h2>
                <p className="mb-3">Verantwortlicher für die Datenverarbeitung im Sinne der Datenschutzgesetze ist:</p>
                <p className="mb-1">Entdecke Solar GbR</p>
                <p className="mb-1">Bischof-Ferdinand-Weg 8, 57368 Lennestadt</p>
                <p className="mb-1">0155-60158289</p>
                <p className="mb-1">info@entdecke.solar</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">3. Erhebung und Verarbeitung personenbezogener Daten</h2>
                <p className="mb-3">Wir erheben personenbezogene Daten, wenn Sie unsere Website besuchen oder unsere Dienstleistungen in Anspruch nehmen. Zu den personenbezogenen Daten gehören:</p>
                <ul className="list-disc pl-5 mb-3">
                    <li>Name</li>
                    <li>E-Mail-Adresse</li>
                    <li>Telefonnummer</li>
                    <li>Adresse</li>
                    <li>Nutzungsdaten (z.B. IP-Adresse, Browsertyp)</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">4. Zweck der Verarbeitung</h2>
                <p className="mb-3">Wir verwenden Ihre personenbezogenen Daten für folgende Zwecke:</p>
                <ul className="list-disc pl-5 mb-3">
                    <li>Bereitstellung und Verbesserung unserer Dienstleistungen</li>
                    <li>Kundenkommunikation</li>
                    <li>Marketingzwecke (sofern Sie dem zugestimmt haben)</li>
                    <li>Erfüllung rechtlicher Verpflichtungen</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">5. Rechtsgrundlage der Verarbeitung</h2>
                <p className="mb-3">Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf folgenden Rechtsgrundlagen:</p>
                <ul className="list-disc pl-5 mb-3">
                    <li>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)</li>
                    <li>Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO)</li>
                    <li>Rechtliche Verpflichtung (Art. 6 Abs. 1 lit. c DSGVO)</li>
                    <li>Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO)</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">6. Weitergabe von Daten</h2>
                <p className="mb-3">Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, dies ist zur Erfüllung unserer Dienstleistungen erforderlich oder Sie haben ausdrücklich zugestimmt.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">7. Cookies</h2>
                <p className="mb-3">Unsere Website verwendet Cookies, um die Benutzererfahrung zu verbessern. Sie können die Verwendung von Cookies in den Einstellungen Ihres Browsers deaktivieren. Beachten Sie jedoch, dass einige Funktionen der Website möglicherweise nicht mehr ordnungsgemäß funktionieren.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">8. Ihre Rechte</h2>
                <p className="mb-3">Sie haben folgende Rechte hinsichtlich Ihrer personenbezogenen Daten:</p>
                <ul className="list-disc pl-5 mb-3">
                    <li>Auskunftsrecht</li>
                    <li>Recht auf Berichtigung</li>
                    <li>Recht auf Löschung</li>
                    <li>Recht auf Einschränkung der Verarbeitung</li>
                    <li>Recht auf Datenübertragbarkeit</li>
                    <li>Widerspruchsrecht</li>
                </ul>
                <p className="mb-3">Um diese Rechte auszuüben, kontaktieren Sie uns bitte über die oben angegebenen Kontaktdaten.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">9. Datensicherheit</h2>
                <p className="mb-3">Wir setzen angemessene technische und organisatorische Maßnahmen ein, um Ihre personenbezogenen Daten vor Verlust, Diebstahl und Missbrauch zu schützen.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">10. Änderungen der Datenschutzrichtlinien</h2>
                <p className="mb-3">Wir behalten uns das Recht vor, diese Datenschutzrichtlinien jederzeit zu ändern. Bitte überprüfen Sie regelmäßig unsere Richtlinien, um über Änderungen informiert zu bleiben.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-700">11. Kontakt</h2>
                <p className="mb-3">Bei Fragen oder Anliegen zum Datenschutz kontaktieren Sie uns bitte unter:</p>
                <p className="mb-1">info@entdecke.solar</p>
                <p className="mb-1">0155-60158289</p>
            </section>
        </div>
    );
};