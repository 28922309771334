import React from 'react';
import BenefitItem from './BenefitItem';
import { CTAButton } from '../shared/CTAButton';

export const BenefitsSection = () => {
    const benefits = [
        {
            icon: "/assets/MoneyBag.svg",
            title: "Zusätzliches Einkommen",
            subtext: "Durch die Verpachtung Ihrer Fläche generieren Sie regelmäßige Einnahmen, die Ihnen finanzielle Sicherheit bieten und Ihre Investitionen unterstützen."
        },
        {
            icon: "/assets/Leaf.svg",
            title: "Umweltfreundlich",
            subtext: "Ermöglichen Sie die Erzeugung erneuerbarer Energie auf Ihrer Fläche, ohne selbst in Technologie investieren zu müssen. Ein einfacher Weg, zum Klimaschutz beizutragen."
        },
        {
            icon: "/assets/Shield.svg",
            title: "Risikolos",
            subtext: "Als Verpächter tragen Sie keinerlei Investitions- oder Betriebsrisiken. Alle Kosten und Verantwortlichkeiten für die Solaranlage übernimmt der Pächter."
        },
        {
            icon: "/assets/Clock.svg",
            title: "Langfristige Sicherheit",
            subtext: "Profitieren Sie von stabilen, langfristigen Pachteinnahmen. Unsere Verträge bieten Ihnen finanzielle Planungssicherheit über viele Jahre hinweg."
        },
        {
            icon: "/assets/Graph.svg",
            title: "Wertsteigerung der Immobilie",
            subtext: "Die Verpachtung für eine Solaranlage kann den Wert Ihrer Immobilie oder Ihres Grundstücks steigern, ohne dass Sie selbst investieren müssen."
        },
        {
            icon: "/assets/Flexible.svg",
            title: "Flexible Nutzung",
            subtext: "Behalten Sie die Kontrolle über Ihre Fläche. Die Verpachtung ermöglicht eine sinnvolle Nutzung, ohne Ihr Eigentum zu veräußern oder dauerhaft zu verändern."
        }
    ];

    return (
        <div id="benefits" className="md:py-[100px] py-[40px] px-[15px] flex flex-col items-center">
            <div className="max-w-full 2xl:max-w-[1520px] w-full flex flex-col items-center">
                <div className="text-center mb-16 w-full">
                    <h1 className="text-[40px] md:text-[72px] font-medium leading-normal md:leading-[72px] text-[#071943]">
                        Vorteile einer Verpachtung
                    </h1>
                    <p className="mt-4 text-[20px] font-normal leading-[30px] max-w-[793px] mx-auto">
                        Verpachtung ermöglicht es dem Verpächter, Einnahmen zu erzielen, ohne die Immobilie verkaufen zu müssen, und bietet gleichzeitig dem Pächter die Möglichkeit, Grundstücke oder Immobilien flexibel zu nutzen, ohne hohe Investitionskosten tragen zu müssen. Diese vertragliche Regelung fördert eine langfristige Zusammenarbeit und eröffnet wirtschaftliche Chancen für beide Parteien.                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 w-full mb-16">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="flex justify-center">
                            <BenefitItem
                                icon={benefit.icon}
                                title={benefit.title}
                                subtext={benefit.subtext}
                            />
                        </div>
                    ))}
                </div>

                <div className="flex justify-center w-full">
                    <CTAButton />
                </div>
            </div>
        </div>
    );
};