import React from 'react';
import { CustomSlider } from '../ui/Slider';

export const TeamSection = () => {
  return (
    <div id="team" className="">
      <div className="py-[60px] px-[15px]">
        <div className="2xl:max-w-[1530px] mx-auto w-full">
          <h1 className="text-center font-medium text-[40px] md:text-[72px] text-[#071943]">Unser Team</h1>
          <div className="py-5">
            <CustomSlider />
          </div>
        </div>
      </div>
      <img src="/assets/Line 1.svg" className="max-w-full w-full h-auto py-[30px]" alt="" />
    </div>
  );
};
