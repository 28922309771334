import React from 'react';
import { ReviewsSlider } from '../ui/ReviewsSlider';

export const ReviewsSection = () => {
  return (
    <div className="px-[15px] md:py-[100px] py-[40px]">
      <div className="2xl:max-w-[1520px] mx-auto">
        <h1 className="text-[40px] md:text-[72px] leading-normal md:leading-[72px] ">Unsere Bewertungen</h1>
        <div className="py-5">
          <ReviewsSlider />
        </div>
      </div>
    </div>
  );
};
