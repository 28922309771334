import React from 'react';
import { Faq } from '../ui/Faq';

export const FaqSection = () => {
  return (
    <div className="lg:pt-[100px] lg:pb-[150px] py-[40px] px-[15px] mx-auto 2xl:max-w-[1520px] ">
      <div className="text-center">
        <h1 className="text-[40px] lg:text-[72px] ">FAQ</h1>
      </div>
      <div className="pt-5">
        <Faq />
      </div>
    </div>
  );
};
