import React from 'react';

export const Footer = () => {
  const sections = [
    {
      title: 'Firma',
      items: [{ text: "Für Sie", href: "/" }, { text: "Verpachtung", href: "/verpachtung" }],
    },
    {
      title: 'Rechtliches',
      items: [{ text: "Datenschutzrichtlinien", href: "/privacy" }, { text: "Impressum", href: "/imprint" }],
    },
    {
      title: 'Soziale Netzwerke',
      items: [{ text: "Instagram", href: "https://instagram.com/entdeckesolar" }],
    },
  ];
  return (
    <div className="pt-[100px] pb-[50px] border-t border-[#0719431A]">
      <div className="max-w-full px-[15px] 2xl:max-w-[1520px] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="max-w-[408px] w-full">
            <div className="logo">
              <img src="/assets/logo.svg" className="max-w-[125px] h-auto w-full" alt="" />
            </div>
            <p className="py-3 text-[18px] text-[#071943BF] font-normal leading-[23.4px] ">
              Ein neuer Blick auf Energie..
            </p>
            <div className="flex gap-3 items-center">
              <div className="flex gap-2 items-center">
                <img src="/assets/phone.svg" className="w-[24px] h-[24px]" alt="" />
                <p className="  text-[18px] text-[#071943BF] font-normal leading-[23.4px] ">0151-72749535</p>
              </div>
              <div className="flex gap-2 items-center">
                <img src="/assets/mail.svg" className="w-[24px] h-[24px]" alt="" />
                <p className="  text-[18px] text-[#071943BF] font-normal leading-[23.4px] ">info@entdecke.solar</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 pt-4 md:grid-cols-3">
            {sections.map((section, index) => (
              <div key={index}>
                <h1 className="font-bold text-[22px] text-[#071943] pb-4">{section.title}</h1>
                <ul className="p-0 m-0">
                  {section.items.map((item, idx) => (
                    <li key={idx} className="pb-3 text-[18px] font-normal text-[#071943BF]">
                      <a href={item.href}>{item.text}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <img src="/assets/Line 1.svg" className="max-w-full w-full h-auto pt-[60px] pb-[40px] " alt="" />
        <p className="text-[#07194380] text-[18px]">All right reserved. © 2024 Agreements</p>
      </div>
    </div>
  );
};
